const users = [
  {
    username: "odl_docs",
    password: "u4$sAsW9Xlgu6hv$xbtlnk4SP8%CUlF3VlwOQrIL",
  },
  {
    username: "ripple_docs",
    password: "faL3+2aI^MgT];8%:.Z5Llk|pl_(U-Are^~ul4{d",
  },
  {
    username: "lsg_docs",
    password: "RtAAvD/2dMq_k(op_?9Xhl{<fIrqKtVyzUC|gKW_",
  },
  {
    username: "gsr_docs",
    password: "M1,;zAkkkA<mdIfUKFa_:yyd^OS;SC2bmMO6~[b:",
  },
  {
    username: "menai_docs",
    password: "pg!qYi>tzQ}CXri_.nbOMzr@QJ1=<+awV-~l`@=w",
  },
  {
    username: "flowdesk_docs",
    password: "i5!&vc|yc09Pj~xVvJ}$k^P<8HM:C@d/EmwMaVyT",
  },
  {
    username: "g20_docs",
    password: "*IM%bHc[!w0XGgPb{fu`,#w|1_GrFO}v:QOy7nL1",
  },
  {
    username: "keyrock_docs",
    password: "E)Y.UnmKNnGLiW?sX^pv8K3~feW4hYt%5+PyuZHk",
  },
  {
    username: "b2c2_docs",
    password: "HH>gXP=iG96E*>RDpbk_9=j@}ytx>m)jvKtE}mNG",
  },
  {
    username: "tranglo_docs",
    password: "ubLOcAu%Dda2FrRsK09yQd&93qsi8j22HYAVJoKr",
  },
  {
    username: "lorenzo.urera@pdax.ph",
    password: "gwYO@*uFAts6S6jH1iDl$7G8n7%8hi9zSalj6@48",
  },
  {
    username: "xavier.tablatin@pdax.ph",
    password: "m1$9nC6KpzV6SIDbgJ^0n3FBf%rV4Q&!W2X3MPMh",
  },
  {
    username: "alexis@gravityteam.co",
    password: "ZHap%H2YszpWZVa3PWE^DNd3iGB9!bxkx!u7@3zK",
  },
  {
    username: "jrcgalang@chinabank.ph",
    password: "kCyHNnDfJTTHnzqK#oP@ZT8*Xtg7B%V&R@sw^SXq",
  },
  {
    username: "haruko_docs",
    password: "D8EU{=:MPH:F6:Yjh2>p@~1Td2W^RA%Q5)ERUj£g",
  }
];

export default users;

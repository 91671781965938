import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "@reach/router";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PDAX_LOGO from "../../data/images/logo.png";
import { auth } from "../utils/auth";
import isDev from "../utils/isDev";
import users from "../utils/users";
import "../styles/auth.scss";

export default function Auth() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    try {
      const { username, password } = data;

      const user = users.find(
        (user) =>
          user.username === username && user.password === password
      );

      if (!user) {
        toast.error("Invalid credentials. Please try again.", {
          position: "top-center",
        });
      } else {
        auth.setAccessToken(user.password, {
          expires: 1, // 1 day expiration
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (auth.isAuthenticated()) {
    if (!isDev) {
      return <Redirect noThrow to="/" />;
    }

    window.location.href = "/";

    return;
  }

  return (
    <>
      <Helmet title="Authentication | PDAX ODL API" />

      <ToastContainer />

      <div className="auth">
        <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="pdax-logo">
            <img
              src={PDAX_LOGO}
              alt="PDAX Logo"
              className="pdax-logo__image"
            />
            <div className="pdax-logo__title">
              ODL API Documentation
            </div>
          </div>

          <h3 className="auth__title">Login</h3>

          <div className="auth__fields">
            <div className="field">
              <label htmlFor="username" className="field__label">
                Username&nbsp;<sup className="field__required">*</sup>
              </label>
              <input
                autoFocus
                type="text"
                id="username"
                className={`field__input ${
                  !!errors.username ? "field__input--invalid" : ""
                }`}
                placeholder="Username"
                {...register("username", {
                  required: "Username is required.",
                })}
              />
              {errors.username && (
                <p className="field__error">
                  {errors.username?.message}
                </p>
              )}
            </div>

            <div className="field">
              <label htmlFor="password" className="field__label">
                Password&nbsp;<sup className="field__required">*</sup>
              </label>
              <input
                type="password"
                id="password"
                className={`field__input ${
                  !!errors.password ? "field__input--invalid" : ""
                }`}
                placeholder="Password"
                {...register("password", {
                  required: "Password is required.",
                })}
              />
              {errors.password && (
                <p className="field__error">
                  {errors.password?.message}
                </p>
              )}
            </div>
          </div>

          <div className="auth__actions">
            <button type="submit" className="btn btn--primary">
              Submit
            </button>
          </div>

          <p className="copyright">
            &copy; {new Date().getFullYear()} PDAX. All rights reserved.
          </p>
        </form>

        <div className="bg-pattern" />
      </div>
    </>
  );
}
